.socialLinks {
  margin-top: 22.22222222222222vw;
  display: flex;
  flex-direction: column;
  position: fixed;
  float: left;
  max-width: 100%;
  max-height: 100%;
  margin-left: 1.0416666666666667vw;
  background-color: rgba(0, 0, 0, 0.5);
  width: 4.166666666666667vw;
  height: auto;
  padding-top: 0.3472222222222222vw;
  border-radius: 0vw 0.3472222222222222vw 0vw 0.3472222222222222vw;
}

.git {
  width: 3.4722222222222223vw;
  height: 3.4722222222222223vw;
  margin-top: 0.3472222222222222vw;
  margin-bottom: 0.3472222222222222vw;
}

.in {
  width: 3.4722222222222223vw;
  height: 3.4722222222222223vw;
  margin-top: 0.3472222222222222vw;
  margin-bottom: 0.3472222222222222vw;
}

.resume {
  width: 3.4722222222222223vw;
  height: 3.4722222222222223vw;
  margin-top: 0.3472222222222222vw;
  margin-bottom: 0.3472222222222222vw;
}

.App {
  text-align: center;
}

p {
  font-size: 1.3888888888888888vw;
}

.fhhome {
  width: 64.62585034013605vw;
  height: 17.36111111111111vw;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.3472222222222222vw;
  grid-row: 2;
  grid-column: 1;
}

.fhpiclink {
  grid-row: 2;
  grid-column: 1;
}

.ho {
  width: 64.62585034013605vw;
  height: 17.36111111111111vw;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.3472222222222222vw;
  grid-column: 1;
  grid-row: 3;
}

.hopiclink {
  grid-column: 1;
  grid-row: 3;
}

.echome {
  width: 64.62585034013605vw;
  height: 17.36111111111111vw;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.3472222222222222vw;
  grid-column: 1;
  grid-row: 4;
}

.ecpiclink {
  grid-column: 1;
  grid-row: 4;
}

.fhome {
  grid-column: 2;
  grid-row: 2;
  text-align: justify;
  margin-left: 0.6944444444444444vw;
  color: white;

  max-width: 100%;
  max-height: 100%;
}

.fhbox {
  grid-column: 2;
  grid-row: 2;
  text-align: justify;
  margin-left: 2.0833333333333335vw;
  height: 17.36111111111111vw;
  max-width: 100%;
  max-height: 100%;
  padding-right: 0.6944444444444444vw;
  border-radius: 0.3472222222222222vw;
  background-color: rgba(0, 0, 0, 0.5);
  padding-bottom: 2px;
}

.hobox {
  grid-column: 2;
  grid-row: 3;
  text-align: justify;
  margin-left: 2.0833333333333335vw;
  height: 17.36111111111111vw;
  max-width: 100%;
  max-height: 100%;
  padding-right: 0.6944444444444444vw;
  border-radius: 0.3472222222222222vw;
  background-color: rgba(0, 0, 0, 0.5);
}

.hod {
  grid-column: 2;
  grid-row: 3;
  text-align: justify;
  margin-left: 0.6944444444444444vw;
  color: white;

  max-width: 100%;
  max-height: 100%;
}

.ecbox {
  grid-column: 2;
  grid-row: 4;
  text-align: justify;
  margin-left: 2.0833333333333335vw;
  height: 17.36111111111111vw;
  max-width: 100%;
  max-height: 100%;
  padding-right: 0.6944444444444444vw;
  border-radius: 0.3472222222222222vw;
  background-color: rgba(0, 0, 0, 0.5);
}

.ec {
  grid-column: 2;
  grid-row: 4;
  text-align: justify;
  margin-left: 0.6944444444444444vw;
  color: white;

  max-width: 100%;
  max-height: 100%;
}

.mywork {
  background-color: #f9dcbd;
  max-height: 100%;
  max-width: 100%;
  min-width: 360px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.skills {
  background-color: #fff7b0;
  max-height: 100%;
  max-width: 100%;
  min-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.current {
  background-color: #d0cfcd;
  max-height: 100%;
  max-width: 100%;
  min-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.contact {
  background-color: #f9dcbd;
  max-height: 100%;
  max-width: 100%;
  min-width: 360px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.nav {
  width: 40.27777777777778vw;
  height: 3.4722222222222223vw;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-family: "Palatino Linotype";
  font-size: 1.8055555555555556vw;
  font-style: italic;
  font-variant: normal;
  font-weight: 700;
}

a {
  color: white;
  text-decoration: none;
}

.links {
  display: flex;
  flex-direction: row;
  margin: auto;
}

.or {
  margin: 0vw;
  font-size: 1.7361111111111112vw;
}

.alink {
  font-size: 1.7361111111111112vw;
}

.navBox {
  margin-top: 3.4722222222222223vw;
  width: 40.97222222222222vw;
  height: 3.4722222222222223vw;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3472222222222222vw;
  grid-row: 1;
  grid-column: 1;
  justify-content: center;
}

.bio {
  height: 36.041666666666664vw;
  width: 35.27777777777778vw;
  color: white;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.bioBox {
  margin-top: 1.3888888888888888vw;
  height: auto;
  width: 35.763888888888886vw;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3472222222222222vw;
  grid-row: 2 / span 3;

  padding: 1.3888888888888888vw;
}

.bioPic {
  margin-top: 1.3888888888888888vw;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
}

.proPic {
  height: 23.125vw;
  width: 22.22222222222222vw;
  max-width: 100%;

  object-fit: cover;
}

.mainSection {
  display: grid;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  grid-template-rows: 20% 50% 40%;
  justify-content: center;
}

.videoloop2 {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1;
  filter: blur(2.3px);
}

h2 {
  font-family: "Bangers", cursive;
  font-weight: bold;
  font-size: 5.555555555555555vw;

  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
}

h1 {
  font-family: "Bangers", cursive;

  font-weight: bold;
  font-size: 2.7777777777777777vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  max-width: 100%;
  max-height: 100%;
}

.full {
  font-family: sans-serif;
  font-size: 1.3888888888888888vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  max-width: 100%;
  max-height: 100%;
}

.bioHead {
  text-align: justify;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  max-width: 100%;
  max-height: 100%;
}

.work {
  display: grid;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  grid-template-columns: 30% 50%;
  grid-template-rows: 20% 30% 30% 30%;
  justify-content: center;
}

h3 {
  font-family: "Bangers", cursive;
  font-weight: bold;
  font-size: 2.0833333333333335vw;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  max-width: 100%;
  max-height: 100%;
}

.skill {
  display: grid;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  grid-template-columns: 20% 20% 20% 20%;
  grid-template-rows: 20% 50% 50%;
}

.js {
  grid-column: 1;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 14.51388888888889vw;
  width: 10.833333333333334vw;
}

.html {
  grid-column: 2;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 13.26388888888889vw;
  width: 13.26388888888889vw;
}

.css {
  grid-column: 3;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 13.26388888888889vw;
  width: 9.51388888888889vw;
}

.node {
  grid-column: 4;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 13.88888888888889vw;
  width: 13.26388888888889vw;
}

.sql {
  margin-bottom: auto;
  margin-top: auto;
  grid-column: 2;
  grid-row: 3;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 15.277777777777779vw;
  width: 14.166666666666666vw;
}

.react {
  margin-bottom: auto;
  margin-top: auto;
  grid-column: 3;
  grid-row: 3;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 14.166666666666666vw;
  width: 14.166666666666666vw;
}

.learning {
  display: grid;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  grid-template-columns: 20% 20% 20%;
  grid-template-rows: 40% 50%;
}

.ruby {
  margin-left: 2.7777777777777777vw;
  grid-column: 1;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 17.36111111111111vw;
  width: 13.88888888888889vw;
}

.java {
  margin-left: 2.7777777777777777vw;
  grid-column: 2;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 17.36111111111111vw;
  width: 13.88888888888889vw;
}

.python {
  margin-left: 2.7777777777777777vw;
  grid-column: 3;
  grid-row: 2;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  height: 17.36111111111111vw;
  width: 13.88888888888889vw;
}

.formBox {
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
  width: 45.138888888888886vw;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;

  border-radius: 0.3472222222222222vw;
  grid-row: 2;
}

.form {
  display: inline-grid;
  justify-content: center;
  margin-top: 3.4722222222222223vw;
  max-width: 100%;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.email {
  height: 2.0833333333333335vw;
  width: 34.72222222222222vw;
  max-width: 100%;
  max-height: 100%;
  font-size: 1.3888888888888888vw;
  border-radius: 0.3472222222222222vw;
  border-color: white;
  border-style: ridge;
}

.text {
  height: 13.88888888888889vw;
  font-size: 1.3888888888888888vw;
  max-width: 100%;
  max-height: 100%;
  border-radius: 0.3472222222222222vw;
  border-color: white;
  border-style: ridge;
}

.submit {
  -moz-box-shadow: inset 0vw 0.06944444444444445vw 0vw 0vw #ffffff;
  -webkit-box-shadow: inset 0vw 0.06944444444444445vw 0vw 0vw #ffffff;
  box-shadow: inset 0vw 0.06944444444444445vw 0vw 0vw #ffffff;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #ededed),
    color-stop(1, #dfdfdf)
  );
  background: -moz-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
  background: -webkit-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
  background: -o-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
  background: -ms-linear-gradient(top, #ededed 5%, #dfdfdf 100%);
  background: linear-gradient(to bottom, #ededed 5%, #dfdfdf 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ededed', endColorstr='#dfdfdf',GradientType=0);
  background-color: #ededed;
  -moz-border-radius: 0.4166666666666667vw;
  -webkit-border-radius: 0.4166666666666667vw;
  border-radius: 0.4166666666666667vw;
  border: 0.06944444444444445vw solid #dcdcdc;
  display: inline-block;
  cursor: pointer;
  color: #777777;
  font-family: Arial;
  font-size: 1.0416666666666667vw;
  font-weight: bold;
  padding: 0.4166666666666667vw 1.6666666666666667vw;
  text-decoration: none;
  text-shadow: 0vw 0.06944444444444445vw 0vw #ffffff;
}
.submit:hover {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0.05, #dfdfdf),
    color-stop(1, #ededed)
  );
  background: -moz-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
  background: -webkit-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
  background: -o-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
  background: -ms-linear-gradient(top, #dfdfdf 5%, #ededed 100%);
  background: linear-gradient(to bottom, #dfdfdf 5%, #ededed 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#dfdfdf', endColorstr='#ededed',GradientType=0);
  background-color: #dfdfdf;
}
.submit:active {
  position: relative;
  top: 0.06944444444444445vw;
}

.cme {
  display: flex;
  justify-content: center;

  max-width: 100%;
  max-height: 100%;
  margin-bottom: auto;
  margin-left: auto;
  margin-top: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3472222222222222vw;
  grid-row: 1;
  flex-direction: column;
}

.cl {
  display: flex;
  justify-content: center;

  max-width: 100%;
  max-height: 100%;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3472222222222222vw;
  grid-column: 1 / span 3;
  grid-row: 1;
}

.sk {
  display: flex;
  justify-content: center;

  max-width: 100%;
  max-height: 100%;
  margin-top: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3472222222222222vw;
  grid-column: 1 / span 4;
  grid-row: 1;
}

.w {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  max-height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.3472222222222222vw;
  grid-column: 1 / span 2;
  grid-row: 1;
}

#emailadd {
  display: flex;
  justify-content: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0vw 0vw 0.3472222222222222vw 0.3472222222222222vw;

  max-width: 100%;
  max-height: 100%;
  margin-top: 0;
  margin-bottom: 1.3888888888888888vw;
  margin-left: auto;
  margin-right: auto;
}

.contactgrid {
  display: grid;
  justify-content: center;
  grid-template-rows: 40% 80%;
  max-width: 100%;
  max-height: 100%;
  min-width: 360px;
  margin-left: auto;
  margin-right: auto;
}
